import React, { useState, useEffect } from 'react'
import ListOfProducts from '../components/product/productsList'
import ProductCard from '../components/product/productCard'
// import { getProductsRealTime } from '../firebase/obj/product'
import {
    CircularProgress,
    Grid,
    Typography
} from '@material-ui/core'
// import { Context } from '../context'
import useMainContext from '../hooks/useMainContext'
import ProductListGrouped from '../components/product/productListGrouped'

export const ListOfProductsWithData = ({ publicData, setSelected = () => { } }) => {
    const [loading, setLoading] = useState(true)
    const { productsFiltered, cart, addToCart, setCart, productGroups, config, search } = useMainContext()
    const [productsGrouped, setProductsGrouped] = useState([])

    useEffect(() => {
        if (productsFiltered?.length > 0) {
            setLoading(false)
        }
    }, [productsFiltered])

    useEffect(() => {
        // console.log('productGroups', productGroups);
        if (config?.productListGrouped && productGroups?.length > 0) {

            // Agrupar los productos por grupo y ordenarlos por group.sort
            const groupedProducts = productGroups.map(group => ({
                isProductGroup: true,
                ...group,
                products: productsFiltered.filter(product => product.active && group?.products?.includes(product.id)) || [],
            })).sort((a, b) => a.sort - b.sort);

            // Encontrar los productos que no tienen grupo asignado
            const ungroupedProducts = productsFiltered.filter(product =>
                !groupedProducts.some(group => group?.products?.some(p => p.id === product.id)) && product.active
            );

            // Agregarlos a una categoría "Sin grupo"
            if (ungroupedProducts?.length > 0) {
                groupedProducts.push({
                    isProductGroup: false,
                    name: "Otras opciones",
                    nameVisible: true,
                    products: ungroupedProducts,
                });
            }

            // Filtrar los grupos que no tienen productos ni están activos hoy
            const today = new Date().getDay()
            const _groupedProducts = groupedProducts.filter(group => group.products.length > 0 && group.activeDays?.includes(today))

            // console.log('Grouped Products:', _groupedProducts)
            setProductsGrouped(_groupedProducts)
        }
    }, [config, productGroups, productsFiltered]);

    const createSecondary = (prod) => prod.ingredients

    return (
        <>
            {
                publicData ?
                    loading ?
                        <Grid container direction="column" justify="center" alignItems="center" style={{ height: '70vh' }}>
                            <Grid item>
                                <CircularProgress style={{ color: '#F9D100' }} />
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={0} direction="row" justify="center" alignItems="center" >
                            {
                                productsGrouped?.length <= 1 ?
                                    productsFiltered.map(prod => prod.active &&
                                        <Grid item key={prod.id}>
                                            <ProductCard key={prod.id} product={prod} cart={cart} setCart={setCart} addToCart={addToCart} width={'100%'} />
                                        </Grid>
                                    )
                                    :
                                    <ProductListGrouped productsGrouped={productsGrouped} />
                            }
                            {
                                search && productsFiltered.length === 0 &&
                                <Grid item>
                                    <Typography variant='h6'>No se encontró {search} 😔</Typography>
                                </Grid>
                            }
                        </Grid>
                    :
                    productsFiltered.length === 0 ?
                        <p>No hay productos para mostrar </p>
                        :
                        <ListOfProducts items={productsFiltered} createSecondary={createSecondary} setSelected={setSelected} />
            }
        </>
    )
}

export default ListOfProductsWithData
