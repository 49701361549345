import React, { createContext, useEffect, useState } from 'react'
import { validateClient, validateClientAddress } from '../validators/client'
import {
    saveClient,
    saveClientAddress,
    getClientAddresses,
    updateClientAddress
} from '../firebase/obj/client'
import { calculateTotalOrder } from '../utils/order'
import { deleteOrder, saveOrder, updateOrder } from '../firebase/obj/order'
import useMainContext from '../hooks/useMainContext'
import useZoneContext from '../hooks/useZoneContext'
import moment from 'moment'
import { isPointInsideZone } from '../utils'

export const OrderContext = createContext()

const Provider = ({ children }) => {
    const [order, setOrder] = useState({})
    const [error, setError] = useState()
    const { config } = useMainContext()
    const { zones } = useZoneContext()

    // TODO: setear la lista de pedidos segun el usuario logueado
    // const [orders, setOrders] = useState([])

    useEffect(() => {
        if (config && !order?.id) {
            if (!order?.deliveryPrice) {
                const _order = { ...order, deliveryPrice: Number(config?.deliveryPrice || 0) }
                const amount = calculateTotalOrder(_order)
                _order.amount = amount
                setOrder(_order)
            }
        }
    }, [config])

    // useEffect(() => {
    //     console.log('order', order)
    // }, [order])

    const handleSendOrder = () => {
        // console.log('handleSendOrder', order)
        return saveOrder(order)
    }


    const handleSaveClient = (client) => new Promise((resolve, reject) => {
        try {
            let newClient = false

            const clientValidation = validateClient(client)

            if (!clientValidation.valid)
                return clientValidation


            if (!client.id) {
                newClient = true
                client.active = true
            }
            saveClient(client)
                .then(result => {
                    if (newClient) {
                        setOrder((lastState) => {
                            return { ...lastState, clientId: result.id }
                        })
                    }
                    resolve({ message: 'Cliente guardado', id: result.id })
                })
                .catch(reject)


        } catch (error) {
            console.error('handleSaveClientData', error)
            setError(error?.message)
        }
    })

    const handleSaveClientAddress = (address) => new Promise((resolve, reject) => {
        try {
            const addressValidation = validateClientAddress(address)

            if (!addressValidation.valid)
                return addressValidation

            // console.log('handleSaveClientAddress', address)
            if (!address.id) {
                address.active = true
                address.principal = true
                saveClientAddress(address)
                    .then(({ id }) => {
                        address.id = id
                        setOrder((lastState) => {
                            return { ...lastState, address }
                        })
                        resolve({ message: 'Dirección guardada', address })
                    })
                    .catch(reject)

            } else {
                updateClientAddress(address)
                    .then(() => {
                        resolve({ message: 'Dirección actualizada', address })
                    })
                    .catch(reject)
            }

        } catch (error) {
            console.error('handleSaveClientAddress', error)
            setError(error?.message)
        }
    })

    const handleSetOrder = (o) => {
        try {
            // console.log('handleSetOrder', order)
            if (o?.type === 'table' || o?.pickup) {
                // console.log('table or pickup')
                o.deliveryPrice = 0
            } else if (!o.deliveryPrice) {
                // console.log('no delivery price')
                o.deliveryPrice = Number(config?.deliveryPrice || 0)
            }

            // console.log('o.position', o.position)
            if (o.position && o.position.length) {
                // console.log('busco zona', o.position, zones)
                const zone = isPointInsideZone(o.position, zones)
                // console.log('zone', zone)
                if (zone) {
                    o.deliveryPrice = Number(zone.price)
                    o.zoneName = zone.name
                    o.zoneId = zone.id
                } else {
                    o.deliveryPrice = Number(config?.deliveryPrice || 0)
                    o.zoneName = ''
                    o.zoneId = ''
                }
            }
            const amount = calculateTotalOrder(o)
            o.amount = amount
            if (!o.id) {
                o.orderState = 'En cocina'
                // console.log('handleSetOrder', order.date)
                if (!o.date || moment(o.date).isBefore(moment())) {
                    o.date = new Date()
                }
            }
            setOrder(o)
        } catch (error) {
            console.error('handleSetOrder', error)
        }

    }

    const handleUpdateOrder = (order) => updateOrder(order)
    const handleDeleteOrder = (id) => deleteOrder(id)

    const clearCurrentOrder = () => setOrder({ deliveryPrice: config?.deliveryPrice || 0, position: config?.position || [] })

    const value = {
        order,
        setOrder: handleSetOrder,
        sendOrder: handleSendOrder,
        updateOrder: handleUpdateOrder,
        deleteOrder: handleDeleteOrder,
        clearCurrentOrder,
        getClientAddresses,
        saveClient: handleSaveClient,
        saveClientAddress: handleSaveClientAddress
    }

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    )
}

const context = {
    Provider,
    Consumer: OrderContext.Consumer
}

export default context