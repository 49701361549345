import React, { useState, useEffect } from 'react'
import {
    Grid,
    FormControl,
    FormControlLabel,
    Button,
    Checkbox,
    Typography,
    Card,
    CardContent,
    useMediaQuery,
    TextField
} from '@material-ui/core'
//import clsx from 'clsx'
import Dialog from '../../dialog'
import { useStyles } from './styles'
import { ProductVariant } from './productVariant'
import { createSmartNotesFromOrderProducts, formatSmartNotes, smartNotesToList } from '../../../utils/productEdit'
import SmartNotes from '../smartNotes'
import { set } from 'firebase/database'

const voidF = () => { }

export default function EditProductOrder({ isAdmin, children, itemToEdit = {}, openForm, setOpenForm = voidF, onConfirm = voidF }) {
    const classes = useStyles()
    const [list, setList] = useState([])
    const [smartNotes, setSmartNotes] = useState('')
    const [extras, setExtras] = useState([])

    const notSmall = useMediaQuery('(min-width:600px)')
    const [dialogParams, setDialogParams] = useState({
        maxWidth: 'md',
        itemXs: 4
    })


    useEffect(() => {
        return generateDialogParams()
    }, [notSmall])

    useEffect(() => {
        const renderData = () => {
            const { smartComposition = [] } = itemToEdit
            generateDialogParams()
            setList(smartComposition)
            if (smartComposition.length > 0) {
                const firstItem = smartComposition.at(0)
                const _extras = firstItem.composition?.filter(s => s.isExtra) || []
                setExtras(_extras)
            }
        }
        return renderData()
    }, [itemToEdit])

    useEffect(() => {
        const _smartNotes = createSmartNotesFromOrderProducts(list)
        setSmartNotes(_smartNotes)
    }, [list])

    const generateDialogParams = () => {
        const { smartComposition = [] } = itemToEdit

        if (smartComposition.length === 1 || !notSmall) {
            return setDialogParams({
                maxWidth: 'xs',
                itemXs: 12
            })
        }

        if (smartComposition.length === 2) {
            return setDialogParams({
                maxWidth: 'sm',
                itemXs: 6
            })
        }

        return setDialogParams({
            maxWidth: 'md',
            itemXs: 4
        })
    }


    const handleConfirm = () => {
        const product = {
            ...itemToEdit,
            smartComposition: list,
            smartNotes
        }
        onConfirm(product)
        setOpenForm(false)
    }


    const onCheck = (product, supply, attributeName = 'checked') => {
        const items = list.filter((item) => {
            if (product._cod === item._cod) {
                item.composition = item.composition.map(sup => {
                    if (sup.id === supply.id) {
                        return {
                            ...sup,
                            [attributeName]: !sup[attributeName]
                        }
                    }
                    return sup
                })

                return supply
            }
            return item
        })
        setList(items)
    }

    const supplyDescription = (supply) => {
        let text = supply.shortDescription || supply.description || supply.name || ''
        return text.toUpperCase()
    }


    const handleChangeNote = (itemToEdit, notes) => {
        const items = list.filter((item) => {
            if (item._cod === itemToEdit._cod) {
                item.notes = notes
                return item
            }
            return item
        })
        setList(items)
    }


    return (
        <>
            <Dialog disableBackdropClick={true} message={itemToEdit.name} fullWidth={true} maxWidth={dialogParams.maxWidth} open={openForm} setOpen={setOpenForm} acceptText={null} cancelText={null} >
                {children}
                <FormControl className={classes.formControl}>
                    {
                        isAdmin && <>
                            <Typography>Comentarios:</Typography>
                            <SmartNotes smartNotes={smartNotes} />
                        </>
                    }
                    <Grid direction="row" container className={classes.listContainer}>
                        {
                            list?.map((item, i) =>
                                <Grid key={item._cod} item xs={dialogParams.itemXs}>
                                    <Card className={classes.card} >
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">#{item._cod}</Typography>
                                            <Grid container>
                                                {
                                                    item.composition?.filter(s => s.optional).map((supply) =>
                                                        <Grid item xs={12} key={supply.id}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={supply.checked} onChange={e => onCheck(item, supply)} value={supply.id} />
                                                                }
                                                                label={supplyDescription(supply)}
                                                                style={{ paddingLeft: 10 }}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    <ProductVariant product={item} list={list} setList={setList} />
                                                }
                                                {
                                                    extras?.length > 0 &&
                                                    <Grid item xs={12}>
                                                        <Typography>Extras:</Typography>
                                                        {
                                                            extras.map((supply) => {
                                                                const productSupply = item.composition?.find(s => s.id === supply.id)
                                                                return (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox checked={productSupply?.extraChecked} onChange={e => onCheck(item, { ...supply, _cod: item._cod }, 'extraChecked')} value={supply.id} />
                                                                        }
                                                                        label={`+${supply.extraPrice ? ('$' + supply.extraPrice) : ''} ${supplyDescription(supply)} `}
                                                                        style={{ paddingLeft: 10 }}
                                                                    />
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </Grid>
                                                }
                                                <Grid item xs={12} >
                                                    <TextField label='Aclaración' value={item.notes || ''} onChange={(e) => handleChangeNote(item, e.target.value)} fullWidth />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }

                    </Grid>
                    <Grid
                        container
                        direction="row-reverse"
                        justify="flex-end"
                        alignItems="flex-start"
                        className={classes.gridActions}
                    >
                        <Button variant="outlined" color="primary" onClick={handleConfirm} >Confirmar</Button>
                    </Grid>
                </FormControl>
            </Dialog>
        </>
    )
}